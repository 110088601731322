.about {
  margin-top: 93px;
  position: relative;
  padding-bottom: 30px;
  
  &:before {
    display: block;
    content: "";

    position: absolute;
    width: 2006px;
    height: 948px;
    left: 50%;
    margin-left: -1003px;
    top: 0;

    background: url('#{$rootUrl}about.svg') no-repeat center;
    background-size: contain;
  }

  .main-container {
    position: relative;
    z-index: 20;
    max-width: 1539px;
  }

  &__poligon-bg {
    position: absolute;
    z-index: 5;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    &--1 {
      background-image: url("#{$rootUrl}poligon-bg-10.svg");
      width: 375px;
      height: 375px;
      top: 583px;
      left: -183px;
      z-index: 6;
    }
    
    &--2 {
      background-image: url("#{$rootUrl}poligon-bg-11.svg");
      width: 125px;
      height: 117px;
      top: 899px;
      left: -9px;
    }
        
    &--3 {
      background-image: url("#{$rootUrl}poligon-bg-12.svg");
      width: 297px;
      height: 314px;
      top: 376px;
      right: -189px;
      z-index: 6;
    }
            
    &--4 {
      background-image: url("#{$rootUrl}poligon-bg-13.svg");
      width: 115px;
      height: 115px;
      top: 631px;
      right: -29px;
    }
  }

  &__inner {
    position: relative;
    z-index: 20;
  }

  &__header {
    text-align: center;
    padding-top: 100px;

    &-btn {
      width: 284px;
      margin: 71px 0 0;
    }
  }

  &__title {
    margin: 0 0 64px;
  }

  &__text {
    max-width: 841px;
    margin: auto;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.56;
    color: #919AA3;

    p {
      margin: 0 0 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.about-list {
  padding: 0;
  margin: 195px 0 0;
  list-style: none;
  display: flex;
  justify-content: center;

  &__item {
    width: 390px;
    height: 750px;
    position: relative;
    z-index: 2;
    overflow: hidden;
    // background: #F6BF4C;
    box-shadow: 0px 8px 34px #E1B048;
    border-radius: 24px;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      content: "";
      background: #1C2121;
      opacity: 0.59;
      z-index: 5;
    }

    &:not(:first-child) {
      margin-left: -15px;
    }

    &--1 {
      margin-top: 75px;
    }

    &--2 {
      z-index: 10;
    }

    &--3 {
      z-index: 20;
      margin-top: 104px;
    }

    &--4 {
      z-index: 30;
    }

    &:hover {
      .about-list__img {
        transform: scale(1.1);
      }

      .about-list__arrow {
        transform: translate(5px,0);
      }
    }
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: .25s;
  }

  &__inner {
    position: relative;
    z-index: 10;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 100%;
  }

  &__title {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    font-weight: 700;
    font-size: 40px;
    line-height: 0.9;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: 80px 27px 0;
  }

  &__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    padding: 0 27px;
    margin-top: 43px;

    color: #FFFFFF;

    p {
      margin: 0 0 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__date {
    position: absolute;
    left: -33px;
    bottom: 100px;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 8px;
    text-transform: uppercase;
    color: #FFFFFF;
    transform: rotate(-90deg);
  }

  &__arrow {
    position: absolute;
    right: 28px;
    bottom: 36px;
    width: 73px;
    height: 73px;
    transition: 0.3s transform ease;
    color: #F6BF4C;
  }

  &__link-to {
    position: absolute;
    z-index: 50;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@media(max-width: 1440px) {
  $size: 0.8;

  .about {
    margin-top: round(93px * $size);
    padding-bottom: round(30px * $size);
    
    &:before {
      width: round(2006px * $size);
      height: round(948px * $size);
      margin-left: round(-1003px * $size);
    }
  
    .main-container {
      max-width: round(1539px * $size);
    }
  
    &__poligon-bg {
      &--1 {
        width: round(375px * $size);
        height: round(375px * $size);
        top: round(583px * $size);
        left: round(-183px * $size);
      }
      
      &--2 {
        width: round(125px * $size);
        height: round(117px * $size);
        top: round(899px * $size);
        left: round(-9px * $size);
      }
          
      &--3 {
        width: round(297px * $size);
        height: round(314px * $size);
        top: round(376px * $size);
        right: round(-189px * $size);
      }
              
      &--4 {
        width: round(115px * $size);
        height: round(115px * $size);
        top: round(631px * $size);
        right: round(-29px * $size);
      }
    }
  
    &__header {
      padding-top: round(100px * $size);
  
      &-btn {
        width: round(284px * $size);
        margin: round(71px * $size) 0 0;
      }
    }
  
    &__title {
      margin: 0 0 round(64px * $size);
    }
  
    &__text {
      max-width: round(841px * $size);
      font-size: round(18px * $size);

      p {
        margin: 0 0 round(15px * $size);
  
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  
  .about-list {
    margin: round(195px * $size) 0 0;

    &__item {
      width: round(390px * $size);
      height: round(750px * $size);
      border-radius: round(24px * $size);
  
      &:not(:first-child) {
        margin-left: round(-15px * $size);
      }
  
      &--1 {
        margin-top: round(75px * $size);
      }
  
      &--3 {
        margin-top: round(104px * $size);
      }
    }
  
    &__title {
      font-size: round(40px * $size);
      padding: round(80px * $size) round(27px * $size) 0;
    }
  
    &__date {
      left: round(-33px * $size);
      bottom: round(100px * $size);
      font-size: round(18px * $size);
      line-height: round(25px * $size);
      letter-spacing: round(8px * $size);
    }
  
    &__arrow {
      right: round(28px * $size);
      bottom: round(36px * $size);
      width: round(73px * $size);
      height: round(73px * $size);
    }
  }
}

@media(max-width: 1024px) {
  $size: 0.6;

  .about {
    margin-top: round(93px * $size);
    padding-bottom: round(30px * $size);

    &:before {
      width: round(2006px * $size);
      height: round(948px * $size);
      margin-left: round(-1003px * $size);
    }

    .main-container {
      max-width: round(1539px * $size);
    }

    &__poligon-bg {
      &--1 {
        width: round(375px * $size);
        height: round(375px * $size);
        top: round(583px * $size);
        left: round(-183px * $size);
      }

      &--2 {
        width: round(125px * $size);
        height: round(117px * $size);
        top: round(899px * $size);
        left: round(-9px * $size);
      }

      &--3 {
        width: round(297px * $size);
        height: round(314px * $size);
        top: round(376px * $size);
        right: round(-189px * $size);
      }

      &--4 {
        width: round(115px * $size);
        height: round(115px * $size);
        top: round(631px * $size);
        right: round(-29px * $size);
      }
    }

    &__header {
      padding-top: round(100px * $size);

      &-btn {
        width: round(284px * $size);
        margin: round(71px * $size) 0 0;
      }
    }

    &__title {
      margin: 0 0 round(64px * $size);
    }

    &__text {
      max-width: round(841px * $size);
      font-size: round(18px * $size);

      p {
        margin: 0 0 round(15px * $size);

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .about-list {
    margin: round(195px * $size) 0 0;

    &__item {
      width: round(390px * $size);
      height: round(750px * $size);
      border-radius: round(24px * $size);

      &:not(:first-child) {
        margin-left: round(-15px * $size);
      }

      &--1 {
        margin-top: round(75px * $size);
      }

      &--3 {
        margin-top: round(104px * $size);
      }
    }

    &__title {
      font-size: round(40px * $size);
      padding: round(80px * $size) round(27px * $size) 0;
    }

    &__date {
      left: round(-33px * $size);
      bottom: round(100px * $size);
      font-size: round(18px * $size);
      line-height: round(25px * $size);
      letter-spacing: round(8px * $size);
    }

    &__arrow {
      right: round(28px * $size);
      bottom: round(36px * $size);
      width: round(73px * $size);
      height: round(73px * $size);
    }
  }
}

@media(max-width: 767px) {
  $size: 0.5;

  .about {
    margin-top: round(93px * $size);
    padding-bottom: round(30px * $size);

    &-list {
      display: block;

      &__item {
        margin-left: auto;
        margin-right: auto;
      }

      .about-list__item:not(:first-child) {
        margin-left: auto;
        margin-right: auto;
      }
    }

    &:before {
      width: round(2006px * $size);
      height: round(948px * $size);
      margin-left: round(-1003px * $size);
    }

    .main-container {
      max-width: round(1539px * $size);
    }

    &__poligon-bg {
      &--1 {
        width: round(375px * $size);
        height: round(375px * $size);
        top: round(583px * $size);
        left: round(-183px * $size);
      }

      &--2 {
        width: round(125px * $size);
        height: round(117px * $size);
        top: round(899px * $size);
        left: round(-9px * $size);
      }

      &--3 {
        width: round(297px * $size);
        height: round(314px * $size);
        top: round(376px * $size);
        right: round(-189px * $size);
      }

      &--4 {
        width: round(115px * $size);
        height: round(115px * $size);
        top: round(631px * $size);
        right: round(-29px * $size);
      }
    }

    &__header {
      padding-top: round(100px * $size);

      &-btn {
        width: round(284px * $size);
        margin: round(71px * $size) 0 0;
      }
    }

    &__title {
      margin: 0 0 round(64px * $size);
      font-size: 32px;
    }

    &__text {
      max-width: 540px;
      font-size: 14px;

      p {
        font-size: 12px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .about-list {
    margin: round(195px * $size) 0 0;

    &__item {
      width: round(390px * $size);
      height: round(750px * $size);
      border-radius: round(24px * $size);

      &--2 {
        margin-top: 40px;
      }

      &--3 {
        margin-top: 40px;
      }

      &--4 {
        margin-top: 40px;
      }
    }

    &__title {
      font-size: round(40px * $size);
      padding: round(80px * $size) round(27px * $size) 0;
    }

    &__text {
      font-size: 12px;
    }

    &__date {
      left: round(-33px * $size);
      bottom: round(100px * $size);
      font-size: round(18px * $size);
      line-height: round(25px * $size);
      letter-spacing: round(8px * $size);
    }

    &__arrow {
      right: round(28px * $size);
      bottom: round(36px * $size);
      width: round(73px * $size);
      height: round(73px * $size);
    }
  }
}

@media(max-width: 480px) {
  .about {

    &__title {
      font-size: 32px;
    }
  }
}
