.tournaments {
  padding-top: 68px;
  padding-bottom: 30px;

  .swiper-wrapper {
    align-items: center;
    padding: 50px 0 50px 0;
    // height: 637px;
  }
}

.tournaments-slider {
  width: 1046px;
  margin: auto;
  overflow: visible;
  padding: 20px;

  .swiper-slide {
    // width: 882px;
    transition: 0.3s all ease;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
  }

  &__inner {
    display: block;
    text-decoration: none;
    transition: 0.3s all ease;
    background: #fff;
    position: relative;
    overflow: hidden;
    border-radius: 70px;
    // height: 533px;
    width: 100%;
    transition: 0.3s all ease;
    box-shadow: 0px 8px 34px #E1B048;

  }

  &__img {
    display: block;
    width: 100%;
    // height: 100%;
    // object-fit: cover;
  }

  .swiper-slide-active {
    // width: 1046px;

    .tournaments-slider {
      &__inner {
        // height: 637px;
        box-shadow: 0px 8px 74px #E1B048;
      }
    }
  }
}

@media(max-width: 1440px) {
  .tournaments {
    padding-top: 50px;
  }

  .tournaments-slider {
    max-width: 800px;
  }
}

@media(max-width: 1024px) {
  .tournaments {

    &-slider__inner {
      border-radius: 20px;
    }
  }
}