.header {
  padding-top: 40px;
  padding-bottom: 40px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 500;
  transition: 0.3s all ease;

  &__inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__logo {
    width: 124px;

    &-img {
      display: block;
      width: 100%;
    }
  }

  &--sticky {
    padding-top: 20px;
    padding-bottom: 20px;
    background: #161616;
  }
}

.menu__content {
  padding: 0;
  margin: 0 0 0 59px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;

  .menu__item {
    &:not(:last-child) {
      margin-right: 54px;
    }
  }

  .menu__link {
    color: #fff;
    font-weight: 500;
    text-decoration: none;
    transition: 0.3s color ease;

    &:hover {
      color: #dead45;
    }
  }
}

.lang {
  margin-left: 45px;
  position: relative;

  &__selected {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    cursor: pointer;

    &:before {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 5px 0 5px;
      border-color: #fff transparent transparent transparent;
      display: block;
      content: '';
      position: absolute;
      top: 17px;
      left: -22px;
    }

    &-icon {
      width: 35px;
      margin-left: 18px;

      img {
        display: block;
        width: 100%;
      }
    }

    &-name {
      color: #fff;
      display: block;
      font-weight: 500;
      font-size: 16px;
      line-height: 1.375;
    }
  }

  &__drop {
    padding-top: 10px;
    position: absolute;
    left: -48px;
    top: 100%;
    width: 202px;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    transform: translate(0, 50px);
    transition: 0.3s all ease;

    &-inner {
      background: #ffffff;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2),
        0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14);
      border-radius: 13px;
      position: relative;
      padding: 10px 0;
      min-height: 50px;

      &:before {
        position: absolute;
        left: 26px;
        bottom: 100%;
        display: block;
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 5px 5px 5px;
        border-color: transparent transparent #fff transparent;
      }
    }
  }

  &:hover {
    .lang__drop {
      opacity: 1;
      visibility: visible;
      z-index: 200;
      transform: translate(0, 0);
    }
  }
}

.user__drop {  
  position: absolute;
  left: 0;
  top: 100%;
  width: 202px;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  transform: translate(0, 50px);
  transition: 0.3s all ease;
  .lang__drop-inner {
    padding: 10px 20px;
  }
  .lang-drop-list__link {
    padding-left: 0;
    span {
      padding-left: 0;
    }
  }
}

.header__user {
  padding: 7px;
  display: flex;
  align-items: center;
}

.header__user:hover {
  .user__drop {
    opacity: 1;
    visibility: visible;
    z-index: 200;
    transform: translate(0, 0);
  }
}
.lang-drop-list__link {
  cursor: pointer;
}

.lang-drop-list {
  padding: 0;
  margin: 0;
  list-style: none;

  &__item {
    &:not(:last-child) {
      border-bottom: 1px solid #eaeaea;
    }
  }

  &__link {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 12px 20px;
    text-decoration: none;
    color: #2b2b2b;
    transition: 0.3s color ease;

    &:hover {
      color: #dead45;
    }

    &-icon {
      width: 25px;

      img {
        display: block;
        width: 100%;
      }
    }

    &-text {
      flex: 1;
      padding-left: 17px;
      font-weight: 600;
      font-size: 16px;
      line-height: 1.375;
      display: block;
    }
  }
}

@media (max-width: 1600px) {
  .header {
    padding-top: 30px;
    padding-bottom: 30px;

    &--sticky {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
}

.header__burger {
  display: none;
}

@media (max-width: 1024px) {
  .header {
    .lang {
      padding-right: 20px;
    }

    &__inner {
      z-index: 3;
      justify-content: space-between;
    }

    &__logo {
      width: 105px;
    }

    .menu {
      position: fixed;
      z-index: 1;
      top: 0;
      left: 0;
      width: 80%;
      height: 100%;
      background-color: #222222;
      margin-left: 0;
      transform: translateX(-150%);
      transition: all 0.4s;

      &__content {
        height: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 100px;
      }

      &__item {
        margin-right: 54px;
        padding-top: 30px;
        font-weight: 500;
        font-size: 20px;
      }
    }

    .menu.active {
      transform: translateX(0);
    }

    .blur {
      top: 0;
      width: 100vh;
      height: 100vh;
      backdrop-filter: blur(2px);
      position: absolute;
      left: 30%;
      transform: translateX(-150%);
    }

    .blur.active {
      transform: translateX(0);
    }

    &__burger {
      display: block;
      position: relative;
      width: 30px;
      height: 20px;
      z-index: 2;
      cursor: pointer;
    }
    &__burger span {
      position: absolute;
      background-color: #ffffff;
      position: absolute;
      left: 0;
      width: 100%;
      height: 2px;
      top: 9px;
      transition: all 0.3s ease 0s;
    }
    &__burger:before,
    &__burger:after {
      content: '';
      background-color: #ffffff;
      position: absolute;
      width: 100%;
      height: 2px;
      left: 0;
      transition: all 0.3s ease 0s;
    }
    &__burger:before {
      top: 0;
    }
    &__burger:after {
      bottom: 0;
    }

    &__burger.active span {
      transform: scale(0);
    }
    &__burger.active:before {
      transform: rotate(45deg);
      top: 9px;
    }
    &__burger.active:after {
      transform: rotate(-45deg);
      bottom: 9px;
    }
  }
}

@media (max-width: 568px) {
  .header {
    .lang {
      padding-right: 0;

      &__selected:before {
        top: 10px;
        left: -18px;
      }

      &__selected-name {
        font-size: 14px;
      }
      &__selected-icon {
        width: 25px;
        margin-left: 13px;
      }
    }
  }
}
