.main-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1480px;
}

.text-center {
  text-align: center!important;
}

.text-right {
  text-align: right!important;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-left {
  text-align: left!important;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.ml-auto {
  margin-left: auto!important;
}

.mr-auto {
  margin-right: auto!important;
}

.mb-0 {
  margin-bottom: 0!important;
}

.mt-0 {
  margin-top: 0!important;
}

.mt-30 {
  margin-top: 30px!important;
}

.col-1-2 {
  width: 50%;
}

.col-1-3 {
  width: 33.3%;
}

.col-2-3 {
  width: 66.6%;
}

.body-overflow,
.body-hidden {
  overflow: hidden;
}



