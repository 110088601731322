.u-club {
  padding-top: 61px;
  padding-bottom: 24px;
  position: relative;
  z-index: 200;

  .form-label {
    font-family: 'Avenir Next Cyr';
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 20px;
    color: #F6BF4C;
  }


  &__title {
    text-align: center;
    text-transform: uppercase;
    margin: 0 0 50px;
  }

  form {
    z-index: 12321;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    justify-content: center;

    .second-wrap {
      margin-top: 42px;
      margin-left: 42px;

      .captcha {
        margin-left: 8px;
      }
    }

    input {
      margin-bottom: 10px;
    }

    .captcha {
      margin-top: 16px;
      margin-bottom: 10px;
    }

    fieldset {
      display: grid;
      border: 0;

      .id,
      .email,
      .code,
      .deposit {
        font-family: 'Avenir Next Cyr';
        font-style: normal;
        font-weight: 450;
        font-size: 16px;
        line-height: 20px;
        color: #F6BF4C;
      }

      .form-reqs {
        font-family: 'Avenir Next Cyr';
        font-style: normal;
        font-weight: 450;
        font-size: 12px;
        line-height: 15px;
        color: #B6B5B4;
      }

      .userId,
      .userEmail,
      .userDeposit {
        width: 583px;
        height: 72px;
        background: #FFFFFF;
        border: 1px solid #979797;
        border-radius: 21px;
        margin-top: 16px;
        padding: 20px;
        transition: .75s;
      }

      .userCode {
        width: 292px;
        height: 72px;
        background: #FFFFFF;
        border: 1px solid #979797;
        border-radius: 21px;
        margin-top: 16px;
        padding: 20px;
      }

      .emailWrapper {
        position: relative;
        width: fit-content;
      }

      .codeBtnWrapper {
        position: absolute;
        margin-top: 16px;
        right: 0;
        top: 0;
      }

      .codeBtn {
        width: 157px;
        height: 72px;
        border: 0;
        background: #F6BF4C;
        border-radius: 21px;
        font-family: 'Avenir Next Cyr';
        font-style: italic;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #353535;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: .75s ease-in;
      }
    }
  }

  .w-nav {
    justify-content: center;

    &__item:nth-child(3) {
      margin-right: 55px;
    }
  }

  .second-form-wrapper {
    display: block;
    flex-wrap: wrap;
    position: relative;
    justify-content: center;
    margin-top: 36px;

    .deposit-filter-wrap {
      position: relative;
      display: inline-block;
    }

    .filter-img {
      position: absolute;
      height: 30px;
      right: 40px;
      top: 34px;
    }

    .show-filter {
      position: absolute;
      width: 60px;
      height: 60px;
      right: 6px;
      top: 22px;
      cursor: pointer;
      z-index: 20;
      display: inline-block;
    }

    .filter__arrow {
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 5px 0 5px;
      border-color: #000 transparent transparent transparent;
      display: block;
      content: "";
      right: 18px;
      top: 48px;
      cursor: pointer;
    }

    .dropdown-content {
      display: none;
      top: 50px;
      position: absolute;
      background: #ffffff;
      border-radius: 13px;
      position: relative;
      padding: 10px 0;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14);
      min-width: 160px;

      .dropdown-item {
        display: flex;

        p {
          flex: 1 1 0%;
          padding-left: 17px;
          font-weight: 600;
          font-size: 16px;
          line-height: 1.375;
          display: block;
        }
      }
    }

    .dropdown-content:before {
      position: absolute;
      left: 38px;
      bottom: 100%;
      display: block;
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 5px 5px 5px;
      border-color: transparent transparent #fff transparent;
    }

    .dropdown-content label {
      color: black;
      padding: 6px 10px;
      text-decoration: none;
      display: block;
    }

    .dropdown-content label:hover {
      background-color: #ddd;
    }

    .show-filter:hover .dropdown-content {
      display: block;
    }

    [type=radio] {
      position: absolute;
      opacity: 0;
      width: 0;
      height: 0;
    }

    [type=radio]+img {
      cursor: pointer;
      margin: 5px;
      height: 30px;
    }

    [type=radio]:checked+img {
      box-shadow: 10px 5px 5px #e1b048;
    }

    [type=radio]:checked+p {
      color: #e1b048;
    }

    .continueBtn {
      display: block;
      width: 292px;
      height: 72px;
      margin-top: 42px;
      background: #F6BF4C;
      border-radius: 21px;
      font-family: 'Avenir Next Cyr';
      font-style: italic;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #353535;
      cursor: pointer;
      transition: 2s;
      border: none;
    }

    .continueBtn:disabled {
      opacity: 0.5;
      cursor: initial;
    }

    .checkboxText {
      font-family: 'Avenir Next Cyr';
      font-style: normal;
      font-weight: 450;
      font-size: 12px;
      line-height: 15px;
      color: #B6B5B4;

      a {
        color: white;
      }
    }

    .content-wrap {
      display: flex;
      justify-content: center;
    }

    .second-wrap {
      display: flex;
      justify-content: center;
    }

    .privacyTerm-wrap {
      display: flex;
      align-items: center;
      margin-top: 20px;

      .privacyCheckbox {
        margin-right: 10px;
        width: 24px;
        height: 24px;
        background: #FFFFFF;
        border: 1px solid #979797;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}

.currency {
  margin-left: auto;
  position: absolute;
  top: 16%;
  padding: 20px;
  right: 20px;
  cursor: pointer;

  &__selected {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    cursor: pointer;

    &:after {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 5px 0 5px;
      border-color: #000 transparent transparent transparent;
      display: block;
      content: '';
      position: absolute;
      top: 14px;
      right: -22px;
    }

    &-name {
      color: #000;
      display: block;
      font-size: 22px;
      font-weight: 700;
      line-height: 1.375;
    }
  }

  &__drop {
    padding-top: 10px;
    position: absolute;
    right: -18px;
    top: 100%;
    width: 202px;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    transform: translate(0, 50px);
    transition: 0.3s all ease;

    &-inner {
      background: #ffffff;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2),
        0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14);
      border-radius: 13px;
      position: relative;
      padding: 10px 0;
      min-height: 50px;

      &:before {
        position: absolute;
        right: 26px;
        bottom: 100%;
        display: block;
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 5px 5px 5px;
        border-color: transparent transparent #fff transparent;
      }
    }
  }

  &:hover {
    .currency__drop {
      opacity: 1;
      visibility: visible;
      z-index: 200;
      transform: translate(0, 0);
    }
  }
}

.currency-drop-list {
  padding: 0;
  margin: 0;
  list-style: none;

  &__item {
    &:not(:last-child) {
      border-bottom: 1px solid #eaeaea;
    }
  }

  &__link {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 12px 20px;
    text-decoration: none;
    color: #2b2b2b;
    transition: 0.3s color ease;

    &:hover {
      color: #dead45;
    }

    &-currency {
      font-size: 22px;
      font-weight: 700;
    }

    &-text {
      flex: 1;
      padding-left: 17px;
      font-weight: 600;
      font-size: 16px;
      line-height: 1.375;
      display: block;
    }
  }
}

.u-club-filter {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &__item {
    margin: 0 5px 10px;
  }

  &__inner {
    position: relative;
    cursor: pointer;
  }

  &__cont {
    position: relative;
    background: #e5e5e5;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14);
    border-radius: 41px;
    height: 34px;
    padding: 0 14px 0 30px;
    line-height: 34px;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: -0.0293362px;
    color: #000000;
    transition: 0.3s background-color ease;

    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
    user-select: none;

    &-status {
      width: 14px;
      height: 14px;
      border-radius: 100%;
      border: 1px solid #dead45;
      position: absolute;
      top: 50%;
      left: 9px;
      margin-top: -7px;
      transition: 0.3s all ease;

      &:before {
        width: 8px;
        height: 8px;
        border-radius: 100%;
        display: block;
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -4px 0 0 -4px;
        background: #000000;
        transition: 0.3s scale ease, 0.3s opacity ease;
        opacity: 0;
        transform: scale(0);
      }
    }
  }

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;

    &:checked {
      &+.u-club-filter__cont {
        background: #dead45;

        .u-club-filter__cont-status {
          background: #e5e5e5;
          border: 1px solid #ffffff;

          &:before {
            opacity: 1;
            transform: scale(1);
          }
        }
      }
    }
  }
}

.u-club-slider-wrap {
  position: relative;

  .swiper-button-prev {
    left: 40px;
  }

  .swiper-button-next {
    right: 40px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 28px;
    height: 53px;
    transition: 0.3s color ease;
    margin-top: -27px;
    color: #e0af47;

    &:after {
      display: none;
    }

    .icon {
      width: 100%;
      height: 100%;
    }

    &:hover {
      color: lighten(#e0af47, 10%);
    }
  }
}

.u-club-slider {
  position: relative;
  max-width: 1920px;
  overflow: visible;

  .swiper-slide {
    padding: 0 15px;
    width: 420px;
    transition: 0.3s all ease;
    height: 742px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    cursor: pointer;
  }

  .swiper-wrapper {
    align-items: center;
    height: 672px;
  }

  &__inner {
    height: 535px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 7px 24px rgba(83, 83, 83, 0.1);
    border-radius: 41px;
    transition: 0.3s all ease;
    color: #000000;
  }

  &__title {
    margin: 0 0 5px;
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 1.33;
    letter-spacing: 0.266667px;
  }

  &__id {
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.155556px;
  }

  &__img-w {
    width: 103px;
    height: 103px;
    position: relative;
    z-index: 2;
    overflow: hidden;
    margin: 23px auto 0;
    transition: 0.3s all ease;
  }

  &__img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__cont {
    position: relative;
    padding: 37px 9px 19px;
    transition: 0.3s all ease;
  }

  &__cont-inner {
    position: relative;
    z-index: 50;
    min-height: 130px;
  }

  &__cont-img-w {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
  }

  &__cont-img {
    width: 100%;
    display: block;
  }

  &__rate {
    font-weight: 700;
    font-size: 24px;
    line-height: 1.375;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
    margin: 35px 0 0;
    transition: 0.3s all ease;
  }

  &__btn-w {
    text-align: center;
    margin-top: 33px;
    transition: 0.3s all ease;

    .btn {
      width: 255px;
    }
  }

  .swiper-slide-active {
    width: 502px;

    .u-club-slider {
      &__cont {
        padding-top: 30px;
        padding-bottom: 10px;
      }

      &__inner {
        height: 602px;
      }

      &__img-w {
        width: 142px;
        height: 142px;
        margin-top: 30px;
      }

      &__btn-w {
        margin-top: 26px;

        .btn {
          height: 67px;
          width: 286px;
        }
      }
    }
  }

  &--union {
    margin-top: 0;

    .swiper-wrapper,
    .swiper-slide {
      height: 587px;
    }

    .u-club-slider {
      &__inner {
        height: 530px;
        background: #202020;
        color: #fff;
      }

      &__id {
        color: #7b7b7b;
      }
    }

    .u-club-s-list {
      color: #fff;
    }

    .swiper-slide-active {
      .u-club-slider {
        &__inner {
          height: 567px;
        }
      }
    }
  }
}

.u-club-s-list {
  padding: 0;
  margin: auto;
  list-style: none;
  max-width: 320px;
  color: #000000;

  &__item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 14px;
    letter-spacing: 0.155556px;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__icon {
    width: 32px;
    height: 32px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 100%;
    background: #f6bf4c;

    .icon {
      width: 19px;
      height: 19px;

      &--boxes {
        width: 21px;
        height: 21px;
      }

      &--coins {
        width: 18px;
        height: 18px;
      }
    }
  }

  &__label {
    font-weight: 700;
    margin-left: 8px;
  }

  &__text-info {
    margin-left: auto;
    font-weight: 500;
  }
}

@media (max-width: 1600px) {
  $size: 0.8;

  .u-club-slider-wrap {
    .swiper-button-prev {
      left: 40px;
    }

    .swiper-button-next {
      right: 40px;
    }
  }

  .u-club-slider {
    left: round(20px);

    .swiper-slide {
      padding: 0 round(20px * $size);
      width: round(418px * $size);
      height: round(742px * $size);
    }

    .swiper-wrapper {
      height: round(742px * $size);
    }

    &__title {
      margin: 0 0 round(5px * $size);
      font-size: round(24px * $size);
    }

    &__id {
      font-size: round(14px * $size);
    }

    &__img-w {
      width: round(163px * $size);
      height: round(163px * $size);
      margin: round(23px * $size) auto 0;
    }

    &__cont {
      padding: round(37px * $size) round(9px * $size) round(19px * $size);
    }

    &__cont-inner {
      min-height: round(130px * $size);
    }

    &__rate {
      font-size: round(24px * $size);
      margin: 20px 0 0;
    }

    &__btn-w {
      margin-top: round(33px * $size);

      .btn {
        width: round(255px * $size);
        height: round(60px * $size);
      }
    }

    .swiper-slide-active {
      width: round(502px * $size);

      .u-club-slider {
        &__cont {
          padding-top: round(60px * $size);
          padding-bottom: round(10px * $size);
        }

        &__img-w {
          width: round(182px * $size);
          height: round(182px * $size);
          margin-top: round(30px * $size);
        }

        &__rate {
          margin-top: 15px;
        }

        &__btn-w {
          margin-top: round(26px * $size);

          .btn {
            height: round(67px * $size);
            width: round(286px * $size);
          }
        }
      }
    }

    &--union {

      .swiper-wrapper,
      .swiper-slide {
        height: round(587px * $size);
      }

      .u-club-slider {
        &__inner {
          height: round(530px * $size);
        }
      }

      .swiper-slide-active {
        .u-club-slider {
          &__inner {
            height: round(587px * $size);
          }
        }
      }
    }
  }

  .u-club-s-list {
    max-width: round(320px * $size);
  }
}

@media (max-width: 1440px) {
  $size: 0.8;

  .u-club-slider-wrap {

    .swiper-button-prev,
    .swiper-button-next {
      width: round(28px * $size);
      height: round(53px * $size);
      margin-top: round(-27px * $size);
    }
  }

  .u-club-slider {
    left: round(-4px);

    .swiper-slide {
      padding: 0 round(20px * $size);
      width: round(418px * $size);
      height: round(742px * $size);
    }

    .swiper-slide-active .u-club-slider__inner {
      height: 534px;
    }


    .swiper-wrapper {
      height: round(742px * $size);
    }

    &__inner {
      height: round(665px * $size);
      padding: round(49px * $size) 0 0;
    }

    &__title {
      margin: 0 0 round(5px * $size);
      font-size: round(24px * $size);
    }

    &__id {
      font-size: round(14px * $size);
    }

    &__img-w {
      width: round(163px * $size);
      height: round(163px * $size);
      margin: round(23px * $size) auto 0;
    }

    &__cont {
      padding: round(37px * $size) round(9px * $size) round(19px * $size);
    }

    &__cont-inner {
      min-height: round(130px * $size);
    }

    &__rate {
      font-size: round(24px * $size);
      margin: 20px 0 0;
    }

    &__btn-w {
      margin-top: round(33px * $size);

      .btn {
        width: round(255px * $size);
        height: round(60px * $size);
      }
    }

    .swiper-slide-active {
      width: round(502px * $size);

      .u-club-slider {
        &__cont {
          padding-top: round(60px * $size);
          padding-bottom: round(10px * $size);
        }

        &__img-w {
          width: round(182px * $size);
          height: round(182px * $size);
          margin-top: round(30px * $size);
        }

        &__rate {
          margin-top: 15px;
        }

        &__btn-w {
          margin-top: round(26px * $size);

          .btn {
            height: round(67px * $size);
            width: round(286px * $size);
          }
        }
      }
    }

    &--union {

      .swiper-wrapper,
      .swiper-slide {
        height: round(587px * $size);
      }

      .u-club-slider {
        &__inner {
          height: round(530px * $size);
        }
      }

      .swiper-slide-active {
        .u-club-slider {
          &__inner {
            height: round(587px * $size);
          }
        }
      }
    }
  }

  .u-club-s-list {
    max-width: round(320px * $size);
  }
}

@media (max-width: 1280px) {
  $size: 0.72;

  .swiper-button-prev {
    left: 100px;
  }

  .swiper-button-next {
    right: 40px;
  }

  .u-club-slider {
    .swiper-slide {
      padding: 0 round(20px * $size);
      width: round(418px * $size);
      height: round(742px * $size);
    }

    .swiper-wrapper {
      height: round(742px * $size);
    }

    &__inner {
      height: round(665px * $size);
      padding: round(49px * $size) 0 0;
    }

    &__title {
      margin: 0 0 round(5px * $size);
      font-size: round(24px * $size);
    }

    &__id {
      font-size: round(14px * $size);
    }

    &__img-w {
      width: round(163px * $size);
      height: round(163px * $size);
      margin: round(23px * $size) auto 0;
    }

    &__cont {
      padding: round(37px * $size) round(9px * $size) round(19px * $size);
    }

    &__cont-inner {
      min-height: round(130px * $size);
    }

    &__rate {
      font-size: round(24px * $size);
      margin: 20px 0 0;
    }

    &__btn-w {
      margin-top: round(33px * $size);

      .btn {
        width: round(255px * $size);
        height: round(60px * $size);
      }
    }

    .swiper-slide-active {
      width: round(502px * $size);

      .u-club-slider {
        &__cont {
          padding-top: round(60px * $size);
          padding-bottom: round(10px * $size);
        }

        &__inner {
          height: round(742px * $size);
          padding: round(56px * $size) 0 0;
        }

        &__img-w {
          width: round(182px * $size);
          height: round(182px * $size);
          margin-top: round(30px * $size);
        }

        &__rate {
          margin-top: 15px;
        }

        &__btn-w {
          margin-top: round(26px * $size);

          .btn {
            height: round(67px * $size);
            width: round(286px * $size);
          }
        }
      }
    }

    &--union {

      .swiper-wrapper,
      .swiper-slide {
        height: round(587px * $size);
      }

      .u-club-slider {
        &__inner {
          height: round(530px * $size);
        }
      }

      .swiper-slide-active {
        .u-club-slider {
          &__inner {
            height: round(587px * $size);
          }
        }
      }
    }
  }

  .u-club-s-list {
    max-width: round(320px * $size);
  }
}

@media (max-width: 1194px) {
  $size: 0.9;

  .u-club-slider-wrap {
    .swiper-button-prev {
      left: -40px;
    }

    .swiper-button-next {
      right: -40px;
    }

    .swiper-button-prev,
    .swiper-button-next {
      width: round(28px * $size);
      height: round(53px * $size);
      margin-top: round(-27px * $size);
    }
  }

  .u-club-slider {
    left: round(-15px);

    .swiper-slide {
      padding: 0 round(20px * $size);
      width: round(418px * $size);
      height: round(742px * $size);
    }

    &-wrap {
      width: round(400px);
      margin-left: auto;
      margin-right: auto;
    }

    .swiper-wrapper {
      height: round(742px * $size);
    }

    &__inner {
      height: round(665px * $size);
      padding: round(49px * $size) 0 0;
    }

    &__title {
      margin: 0 0 round(5px * $size);
      font-size: round(24px * $size);
    }

    &__id {
      font-size: round(14px * $size);
    }

    &__img-w {
      width: round(163px * $size);
      height: round(163px * $size);
      margin: round(23px * $size) auto 0;
    }

    &__cont {
      padding: round(37px * $size) round(9px * $size) round(19px * $size);
    }

    &__cont-inner {
      min-height: round(130px * $size);
    }

    &__rate {
      font-size: round(24px * $size);
      margin: 20px 0 0;
    }

    &__btn-w {
      margin-top: round(33px * $size);

      .btn {
        width: round(255px * $size);
        height: round(60px * $size);
      }
    }

    &--union {

      .swiper-wrapper,
      .swiper-slide {
        height: round(587px * $size);
      }

      .u-club-slider {
        &__inner {
          height: round(530px * $size);
        }
      }

      .swiper-slide-active {
        .u-club-slider {
          &__inner {
            height: round(587px * $size);
          }
        }
      }
    }
  }

  .u-club-s-list {
    max-width: round(320px * $size);
  }
}

@media (max-width: 1024px) {
  $size: 0.8;

  .welcome__poligon-bg--2 {
    background: none;
  }

  .u-club-slider {
    margin: round(60px * $size) 0 0;
    left: 20px;

    .swiper-slide {
      width: round(418px * $size);
      height: round(742px * $size);
    }

    &-wrap {
      width: round(400px);
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    .swiper-wrapper {
      height: round(742px * $size);
    }

    &__inner {
      height: round(665px * $size);
      padding: round(49px * $size) 0 0;
    }

    &__title {
      margin: 0 0 round(5px * $size);
      font-size: round(24px * $size);
    }

    &__id {
      font-size: round(14px * $size);
    }

    &__img-w {
      width: round(163px * $size);
      height: round(163px * $size);
      margin: round(23px * $size) auto 0;
    }

    &__cont {
      padding: round(37px * $size) round(9px * $size) round(19px * $size);
    }

    &__cont-inner {
      min-height: round(130px * $size);
    }

    &__rate {
      font-size: round(24px * $size);
      margin: 20px 0 0;
    }

    &__btn-w {
      margin-top: round(33px * $size);

      .btn {
        width: round(255px * $size);
        height: round(60px * $size);
      }
    }

    &--union {

      .swiper-wrapper,
      .swiper-slide {
        height: round(587px * $size);
      }

      .u-club-slider {
        &__inner {
          height: round(530px * $size);
        }
      }

      .swiper-slide-active {
        .u-club-slider {
          &__inner {
            height: round(587px * $size);
          }
        }
      }
    }
  }

  .u-club-s-list {
    max-width: round(320px * $size);
  }

  .u-club {

    .first-wrap {


      fieldset {

        .userId,
        .userEmail,
        .userDeposit {
          width: 506px;
        }
      }

      .codeBtn {
        display: block;
        position: relative;
        margin: 0 auto;
      }
    }

    .second-form-wrapper {
      fieldset {
        align-items: center;
        justify-content: center;

        .userDeposit {
          width: 506px;
        }
      }

      .continueBtn {
        display: block;
        margin: 0 auto;
        margin-top: 28px;
      }

      .content-wrap {
        display: block;
      }
    }
  }
}

@media (max-width: 767px) {
  .currency {
    padding: 10px;
  }

  $size: 0.6;

  .u-club-slider-wrap {
    .swiper-button-prev {
      left: -20px;
    }

    .swiper-button-next {
      right: -20px;
    }
  }

  .u-club {
    &__title {
      font-size: 32px;
    }

    .second-form-wrapper {

      .deposit-filter-wrap {
        width: fit-content;
      }

      .dropdown-content {
        right: 100px;
      }

      .dropdown-content:before {
        left: 138px;
      }

      .checkboxText {
        font-size: 14px;
      }
    }


  }

  .u-club-slider {
    .swiper-slide {
      padding: 0 9px;
    }
  }
}

@media (max-width: 567px) {
  .u-club {

    form {
      justify-content: center;
    }

    .form-wrapper {
      .first-wrap {

        .form-reqs {
          display: none;
        }

        fieldset {

          .userId,
          .userEmail {
            width: 300px;
            height: 44px;
            border-radius: 9px;
          }
        }

        .codeBtn {
          width: 144px;
          height: 44px;
          background: #F6BF4C;
          border-radius: 9px;
          font-style: normal;
          font-weight: 450;
          font-size: 16px;
          line-height: 20px;
          color: #0B0B0B;
        }
      }

      .second-wrap {
        margin-left: 0;

        .userCode {
          width: 300px;
          height: 44px;
          border-radius: 9px;
        }
      }
    }

    .second-form-wrapper {
      fieldset {
        align-items: center;
        justify-content: center;

        .userDeposit {
          width: 300px;
          height: 44px;
          border-radius: 9px;
        }
      }

      .continueBtn {
        display: block;
        margin: 0 auto;
        margin-top: 28px;
        width: 144px;
        height: 44px;
        background: #F6BF4C;
        border-radius: 9px;
        font-style: normal;
        font-weight: 450;
        font-size: 16px;
        line-height: 20px;
        color: #0B0B0B;
      }

      .filter-img {
        top: 22px;
      }

      .filter__arrow {
        top: 35px;
      }

      .show-filter {
        width: 44px;
        height: 44px;
        top: 15px;
      }

      .content-wrap {
        display: block;
      }

      .checkboxText {
        width: 250px;
        line-height: 26px;
        margin-left: 35px;
      }

      .privacyTerm-wrap {
        align-items: baseline;
        position: relative;
      }

      .privacyTerm-wrap .privacyCheckbox {
        position: absolute;
        top: 5px;
      }
    }
  }
}

@media (max-width: 485px) {
  .u-club-slider-wrap .swiper-button-prev {
    left: 6px;
  }

  .u-club-slider-wrap .swiper-button-next {
    right: 6px;
  }

  .u-club .second-form-wrapper {
    margin-top: 50px;
  }
  .u-club-slider {
    margin: 0;
  }
  swiper {
    padding: 0;
  }
}

@media (max-width: 340px) {
  .u-club-slider .swiper-slide {
    width: 275px;
  }
}