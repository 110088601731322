@import "variables";
@import "fonts";
@import "vendor";
@import "base";

@import "components";

@import "blocks";


body {
  color: #c5c5c5;

  a {
    color: #E1B048;
  }
}

.error-msg {
  color: rgb(191, 41, 41) !important;
}

.error-input {
  border: 1px solid rgb(191, 41, 41) !important;
  background-color: #ffdadb !important;
}

.success-btn {
  background-color: #3bc95a !important;
  color: #fff !important;
}

.swiper {
  padding: 30px 0;
}

.filterArrows {

  .swiper-button-next,
  .swiper-button-prev {
    color: #E1B048;
  }

  svg {
    display: none;
  }
}

.codeBtn {
  svg {
    margin: auto;
  }
}

.final-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 180px;
  margin-bottom: 180px;
}

.final-icon {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #3bc95a;
}

.final {
  .footer {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
}

.preloaderWrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chipsMsg {
  margin-top: 15px;
  display: block;
  padding: 10px;
  font-size: 13px;
  font-weight: bold;
  font-style: italic;
}

.form-row {
  max-width: 950px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  justify-content: space-between;
}

.header__contollers {
  display: flex;
  position: relative;
  align-items: center;
  margin-left: auto;
}

.header__user-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #E1B048;
  border-radius: 100%;
  width: 30px;
  padding: 7px;
  height: 30px;
  margin-right: 10px;
}

.header__user-icon {
  svg {
    fill: #fff;
  }
}

@media (max-width: 1280px) {
  .header__user-menu {
    display: none;
  }
}

@media (max-width: 991px) {
  .form-row {
    flex-direction: column;
    justify-content: center;

    input {
      width: 100%!important;
    }
  }

  .u-club form .email-wrap, .u-club form fieldset .emailWrapper {
    width: 100%;
  }
  .u-club form fieldset {
    display: block;
    width: 100%;
  }
  .u-club .second-form-wrapper fieldset.deposit-wrap {
    display: grid;
  }
}

@media (max-width: 768px) {
  .lang {
    margin-left: 25px;
  }
  .welcome__poligon-bg {
    display: none;
  }

  .form-btn-group {
    width: 100%;
    text-align: center;
  }
  .u-club form fieldset .codeBtn {
    width: 100px;
    font-size: 13px;
  }
}