@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('#{$rootUrlFonts}AvenirNext/AvenirNextCyr-Bold.eot');
  src: local('Avenir Next Cyr Bold'), local('AvenirNextCyr-Bold'),
      url('#{$rootUrlFonts}AvenirNext/AvenirNextCyr-Bold.eot?#iefix') format('embedded-opentype'),
      url('#{$rootUrlFonts}AvenirNext/AvenirNextCyr-Bold.woff2') format('woff2'),
      url('#{$rootUrlFonts}AvenirNext/AvenirNextCyr-Bold.woff') format('woff'),
      url('#{$rootUrlFonts}AvenirNext/AvenirNextCyr-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('#{$rootUrlFonts}AvenirNext/AvenirNextCyr-Light.eot');
  src: local('Avenir Next Cyr Light'), local('AvenirNextCyr-Light'),
      url('#{$rootUrlFonts}AvenirNext/AvenirNextCyr-Light.eot?#iefix') format('embedded-opentype'),
      url('#{$rootUrlFonts}AvenirNext/AvenirNextCyr-Light.woff2') format('woff2'),
      url('#{$rootUrlFonts}AvenirNext/AvenirNextCyr-Light.woff') format('woff'),
      url('#{$rootUrlFonts}AvenirNext/AvenirNextCyr-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('#{$rootUrlFonts}AvenirNext/AvenirNextCyr-Heavy.eot');
  src: local('Avenir Next Cyr Heavy'), local('AvenirNextCyr-Heavy'),
      url('#{$rootUrlFonts}AvenirNext/AvenirNextCyr-Heavy.eot?#iefix') format('embedded-opentype'),
      url('#{$rootUrlFonts}AvenirNext/AvenirNextCyr-Heavy.woff2') format('woff2'),
      url('#{$rootUrlFonts}AvenirNext/AvenirNextCyr-Heavy.woff') format('woff'),
      url('#{$rootUrlFonts}AvenirNext/AvenirNextCyr-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('#{$rootUrlFonts}AvenirNext/AvenirNextCyr-Thin.eot');
  src: local('Avenir Next Cyr Thin'), local('AvenirNextCyr-Thin'),
      url('#{$rootUrlFonts}AvenirNext/AvenirNextCyr-Thin.eot?#iefix') format('embedded-opentype'),
      url('#{$rootUrlFonts}AvenirNext/AvenirNextCyr-Thin.woff2') format('woff2'),
      url('#{$rootUrlFonts}AvenirNext/AvenirNextCyr-Thin.woff') format('woff'),
      url('#{$rootUrlFonts}AvenirNext/AvenirNextCyr-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('#{$rootUrlFonts}AvenirNext/AvenirNextCyr-Medium.eot');
  src: local('Avenir Next Cyr Medium'), local('AvenirNextCyr-Medium'),
      url('#{$rootUrlFonts}AvenirNext/AvenirNextCyr-Medium.eot?#iefix') format('embedded-opentype'),
      url('#{$rootUrlFonts}AvenirNext/AvenirNextCyr-Medium.woff2') format('woff2'),
      url('#{$rootUrlFonts}AvenirNext/AvenirNextCyr-Medium.woff') format('woff'),
      url('#{$rootUrlFonts}AvenirNext/AvenirNextCyr-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('#{$rootUrlFonts}AvenirNext/AvenirNextCyr-Demi.eot');
  src: local('Avenir Next Cyr Demi'), local('AvenirNextCyr-Demi'),
      url('#{$rootUrlFonts}AvenirNext/AvenirNextCyr-Demi.eot?#iefix') format('embedded-opentype'),
      url('#{$rootUrlFonts}AvenirNext/AvenirNextCyr-Demi.woff2') format('woff2'),
      url('#{$rootUrlFonts}AvenirNext/AvenirNextCyr-Demi.woff') format('woff'),
      url('#{$rootUrlFonts}AvenirNext/AvenirNextCyr-Demi.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('#{$rootUrlFonts}AvenirNext/AvenirNextCyr-Regular.eot');
  src: local('Avenir Next Cyr Regular'), local('AvenirNextCyr-Regular'),
      url('#{$rootUrlFonts}AvenirNext/AvenirNextCyr-Regular.eot?#iefix') format('embedded-opentype'),
      url('#{$rootUrlFonts}AvenirNext/AvenirNextCyr-Regular.woff2') format('woff2'),
      url('#{$rootUrlFonts}AvenirNext/AvenirNextCyr-Regular.woff') format('woff'),
      url('#{$rootUrlFonts}AvenirNext/AvenirNextCyr-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}



