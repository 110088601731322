.footer {
  margin-top: 66px;
  position: relative;
  overflow: hidden;

  .main-container {
    position: relative;
    max-width: 1270px;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 50%;
    width: 2581px;
    margin-left: -1296px;
  }

  &__inner {
    position: relative;
    z-index: 50;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 152px;
    padding-bottom: 100px;
    padding-left: 220px;
  }

  &__logo {
    width: 146px;
  }
}

.footer-nav {
  padding: 0;
  margin: 0 0 0 auto;
  list-style: none;
  display: flex;
  flex-wrap: wrap;

  &__item {
    &:not(:last-child) {
      margin-right: 52px;
    }
  }

  &__link {
    font-weight: 600;
    font-size: 16px;
    color: #000000;
    text-decoration: none;
  }
}

@media(max-width: 1194px) {
  .footer {
    &__inner {
      padding-left: 120px;
    }

    &-nav {
      margin-left: 50px;
    }
  }
}

@media(max-width: 1024px) {
  .footer {

    &__inner {
      padding-left: 0;
    }
  }
}

@media(max-width: 890px) {
  .footer {

    &__inner {
      display: table;
      margin-left: auto;
      margin-right: auto;
      padding-top: 70px;
      padding-bottom: 40px;
    }

    &-nav {
      display: table;
      margin-left: auto;
      margin-right: auto;

      &__item:not(:last-child) {
        margin-right: 0;
        margin-top: 20px;
      }
      &__item {
        margin-right: 0;
        margin-top: 20px;
        text-align: center;
      }
    }
  }
}