.tabs {
	display: flex;
  flex-wrap: wrap;
	position: relative;
	//background-color: #fff;
  justify-content: center;
	padding: 0.95rem;
	border-radius: 99px; // just a high number to create pill effect
	* {
		z-index: 2;
	}

  label {
    order: 1;
    color: #fff;
  }

  .tab-content {
    width: 100%;
    order: 99;
    margin-top: 90px;
    display:none;
    text-align: left;
  }
}

input[type="radio"] {
	display: none;
}

.tab {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 54px;
	width: 280px;
	font-size: 1rem;
	font-weight: 500;
	border-radius: 99px; // just a high number to create pill effect
	cursor: pointer;
	transition: color 0.15s ease-in;
  color: #000;
}

input[type="radio"] {
	&:checked {
		& + label {
			color: #E1B048;
		}
	}
}

input[id="radio-1"] {
	&:checked {
		& ~ .glider {
			transform: translateX(-100%);
		}
    & ~ .content-1 {
			display: block;
		}
	}
}

input[id="radio-2"] {
	&:checked {
		& ~ .glider {
			transform: translateX(0);
		}
    & ~ .content-2 {
			display: block;
		}
	}
}

input[id="radio-3"] {
	&:checked {
		& ~ .glider {
			transform: translateX(100%);
		}
    & ~ .content-3 {
			display: block;
		}
	}
}

.glider {
	position: absolute;
	display: flex;
	height: 54px;
	width: 280px;
	background-color: #e1b04848;
	z-index: 1;
	border-radius: 99px; // just a high number to create pill effect
	transition: 0.25s ease-out;
}

@media (max-width: 700px) {
	.tabs label {
		transform: scale(0.85);
	}
}

@media (max-width: 871px) and (min-width: 600px) {
	.glider {
    display: none;
  }
}

@media (max-width: 599px) {
	input[id="radio-1"] {
    &:checked {
      & ~ .glider {
        transform: translateY(0);
      }
    }
  }
  
  input[id="radio-2"] {
    &:checked {
      & ~ .glider {
        transform: translateY(100%);
      }
    }
  }
  
  input[id="radio-3"] {
    &:checked {
      & ~ .glider {
        transform: translateY(200%);
      }
    }
  }
}