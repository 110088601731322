.partners {
  padding-top: 167px;
  padding-bottom: 30px;

  &__title {
    text-align: center;
    text-transform: uppercase;
    margin: 0 0 90px;
  }
}

.partners-slider {
  overflow: visible;

  &__inner {
    height: 280px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
  }

  &__img {
    display: block;
    transition: 0.3s transform ease, 0.3s opacity ease;
    opacity: 0.8;
    max-width: 100%;

    &--1 {
      width: 225px;
    }

    &--2 {
      width: 245px;
    }

    &--3 {
      width: 222px;
    }

    &--4 {
      width: 222px;
    }

    &--5 {
      width: 176px;
    }
    
    &--6 {
      width: 186px;
    }
        
    &--7 {
      width: 180px;
    }
  }

  .swiper-slide {
    &.swiper-slide-active {
      .partners-slider__img {
        transform: scale(1.2);
        opacity: 1;
      }
    }
  }
}

@media(max-width: 1280px) {
  .partners-slider__img {
    width: 150px;
  }
}

@media(max-width: 767px) {
  .partners {

    &__title {
      font-size: 32px;
    }
  }
}