.benefits {
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;

  &__header {
    margin-bottom: 141px;
    text-align: center;

    p {
      margin: 0;
      font-size: 18px;
      line-height: 1.56;
      color: #919AA3;
      letter-spacing: 0.5px;
      font-weight: 600;
    }
  }

  &__title {
    text-transform: uppercase;
    margin: 0 0 8px;
  }

  .main-container {
    position: relative;
  }

  &__inner {
    position: relative;
    z-index: 50;
  }

  &__poligon-bg {
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &--1 {
      background-image: url("#{$rootUrl}poligon-bg-5.svg");
      width: 515px;
      height: 515px;
      top: 144px;
      left: 34px;
    }

    &--2 {
      background-image: url("#{$rootUrl}poligon-bg-6.svg");
      width: 484px;
      height: 484px;
      top: 417px;
      right: 45px;
    }
        
    &--3 {
      background-image: url("#{$rootUrl}poligon-bg-7.svg");
      width: 295px;
      height: 315px;
      top: 148px;
      right: -189px;
      z-index: 20;
    }

    &--4 {
      background-image: url("#{$rootUrl}poligon-bg-8.svg");
      width: 115px;
      height: 115px;
      top: 69px;
      right: -52px;
      z-index: 10;
    }
    
    &--5 {
      background-image: url("#{$rootUrl}poligon-bg-9.svg");
      width: 88px;
      height: 91px;
      top: 760px;
      left: -21px;
    }
  }

  &__footer {
    text-align: center;
    margin-top: -10px;

    .btn {
      width: 284px;
    }
  }
}

.benefits-list {
  padding: 0;
  margin: 0 -41px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &__item {
    padding: 0 41px;
    // width: calc(100% / 3);
    min-height: 404px;
    margin-bottom: 78px;
  }

  &__inner {
    width: 403px;
    height: 100%;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14);
    border-radius: 41px;
    padding: 44px 15px;
  }

  &__img-w {
    width: 161px;
    height: 161px;
    border-radius: 100%;
    overflow: hidden;
    position: relative;
    z-index: 2;
    margin: auto;
  }

  &__img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__cont {
    margin: 27px auto 0;
    max-width: 302px;
    text-align: center;
    color: #000000;

    p {
      margin: 0 0 15px;

      font-weight: 500;
      font-size: 12px;
      line-height: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__title {
    margin: 0 0 16px;
    font-weight: 700;
    font-size: 24px;
    line-height: 1.33;
  }
}

@media(max-width: 1440px) {
  .benefits {
    &__header {
      margin-bottom: 100px;

      p {
        font-size: 16px;
      }
    }

    &__poligon-bg {
      &--1 {
        width: 412px;
        height: 412px;
        top: 120px;
        left: 20px;
      }

      &--2 {
        width: 388px;
        height: 388px;
        top: 400px;
        right: 25px;
      }
    }

    &__footer {
      .btn {
        width: 228px;
      }
    }
  }

  .benefits-list {
    &__item {
      padding: 0 25px;
      margin-bottom: 60px;
    }

    &__inner {
      width: 350px;
    }
  }
}

@media(max-width: 767px) {
  .benefits {
    &__title {
      font-size: 32px;
    }
  }
}

