.h1, h1,
.h2, h2,
.h3, h3,
.h4, h4,
.h5, h5,
.h6, h6 {
  font-weight: 700;
  line-height: 1.3636;
}


.h2, h2 {
  color: #DEAD45;
  font-size: 44px;
}

.br {
  display: block;
}

@media(max-width: 1440px) {
  .h2, h2 {
    font-size: 36px;
  }
}